<template>
  <div class="login">
    <div class="logo">
      <img src="../../assets/v4logo.png" alt="" style="width:93px;height:93px;margin-top:-13px;">
      <h2>岚山区科技专员工作管理系统</h2>
    </div>
    <div class="login-form">
      <a-spin :spinning="isLoding">
      <h2>重置密码</h2>
        <a-form id="components-form-demo-normal-login" :form="form" @submit="updateSubmit">           
            <a-form-item>
              <a-input ref="phone" v-decorator="[
                'phone',
                {rules: [{ required: true, message: '请输入手机号!' }] }]" placeholder="请输入手机号">
                      <a-icon slot="prefix" type="user" style="color: rgba(0,0,0,.25)" />
              </a-input>
            </a-form-item>

            <a-form-item style="width:250px;display:inline-block;margin-left:24px;"> 
              <a-input v-decorator="[
                'verification',
                { rules: [{ required: true, message: '请输入验证码!' }] }]"  placeholder="请输入验证码">
                      <a-icon slot="prefix" type="lock" style="color: rgba(0,0,0,.25)" />
              </a-input>
              <a-button class="login-form-button" style="width:100px;display:inline-block;margin-left:5px;position:absolute; margin-top:3px" @click="sendcode($event)" v-if="issend">
                验证码
              </a-button>
              <a-button html-type="submit" class="login-form-button" style="width:100px;display:inline-block;margin-left:5px;margin-top:3px;position:absolute" @click="sendcode($event)" v-if="!issend">
                {{count}}s
              </a-button>
          </a-form-item>
            <a-form-item>
              <a-input v-decorator="[
                'newPassWord',
                {rules: [{ required: true, message: '请输入新密码' }] }]" type="password" placeholder="请输入新密码">
                      <a-icon slot="prefix" type="user" style="color: rgba(0,0,0,.25)" />
              </a-input>
               <a href="javascript:void(0)" style="position:absolute;top:20px;right:0" @click="passEvt">去登录</a>
            </a-form-item>
          <a-form-item>
            <a-button type="primary" html-type="submit" class="login-form-button">
              登录
            </a-button>
            </a-form-item>
        </a-form>
      </a-spin>
    </div>
  </div>
</template>

<script>
import {updateSmsPassWord,smsVerification} from "@/httpsAPI/personal/index";
import { values } from 'xe-utils';

export default {
  name: "login",
  data(){
    return{
      isLoding:false,
      count: 60,
      issend: true,
      phone:'',
      isShow:true,
      passPhone:0
    }
  },
  beforeCreate () {
    this.form = this.$form.createForm(this, { name: 'normal_login' });
  },
  methods: {
     passEvt(){
      this.$router.push("/v4login")
    },
    handleSubmit (e) {
      this.isLoding = true
      e.preventDefault();
      this.form2.validateFields((err, values) => {
        values.phone = values.phone.replace(/(^\s*)|(\s*$)/g, "")
        values.verification = values.verification.replace(/(^\s*)|(\s*$)/g, "")
        values.newPassWord = values.newPassWord.replace(/(^\s*)|(\s*$)/g, "")
        if (!err) {
          this.login(values).then(res => {
            if(res.code == 10000){
              this.isLoding = false
              if (this.$route.query.redirect) {
                this.$router.push({ path: this.$route.query.redirect })
              } else {
                this.isLoding = false
                this.$router.push({ path: "/" })
              }
            }else{
              this.isLoding = false
              this.$message.error(res.msg)
            }
          })
        }
      });
    },
    updateSubmit (e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          updateSmsPassWord(values).then(res => {
            if(res.code == 10000){
              this.$message.success({
                content: '重置密码成功',
                duration: 1,
              })
              this.$router.push({ path: '/v4login'})
            }else{
              this.$message.error(res.msg)
            }
          })
        }
      });
    },
    //验证码
    sendcode() {
      this.phone = this.$refs.phone.value
      if(this.phone == null){
        this.issend = true;
      }else{
        const TIME_COUNT = 60;
        if (!this.timer) {
          this.count = TIME_COUNT;
          this.issend = false;
          //这里可以插入$axios调用后台接口
          smsVerification(this.phone).then(res=>{
            if(res.code == 10000){
              this.isLoding = false
            }else{
              this.isLoding = false
              this.$message.error(res.msg)
            }
          })
          this.timer = setInterval(() => {
            if (this.count > 0 && this.count <= TIME_COUNT) {
              this.count--;
            } else {
              this.issend = true;
              clearInterval(this.timer);
              this.timer = null;
            }
          }, 1000);
        }
      }
    }
  },
  created () {
  }
}
</script>

<style lang="less" scoped>
.login {
  .logo{
    display: flex;
    position: fixed;
    top: 30px;
    left: 100px;
    img{
      width: 60px;
      height: 60px;
    }
    h2{
      color: #fff;
      font-weight: 900;
      margin-top: 15px;
      margin-left: 10px;
      font-size: 30px;
    }
  }
  background: url(../../assets/v4bg.jpg) no-repeat;
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  position: relative;
  .login-form {
    width: 400px;
    height: 450px;
    background: #fff;
    overflow: hidden;
    position: absolute;
    right: 200px;
    top: 200px;
    box-shadow: 0 0 30px -5px #fff;
    h2 {
      color: #1890ff;
      font-weight: 600;
      text-align: center;
      margin: 30px 0px;
    }
  }
}
/deep/.ant-tabs-tab{
  width: 50%;
}
/deep/.ant-tabs-nav-scroll{
  margin: 0px 50px;
}
.ant-form {
  margin-top: 20px;
}

/deep/.ant-form-item-control-wrapper {
  width: 70%;
  margin: 0 auto;
}
#components-form-demo-normal-login .login-form-button {
  width: 100%;
}
</style>